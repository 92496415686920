import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BecomeAPartnerThankYou from "../../../page-components/become-a-partner/thank-you";
import bg from "../../../images/svg/vector-07.svg";

const AffiliatePage = () => {
  return (
    <Layout isLanding useNewLPHeader className="non-fixed-width">
      <SEO title="SpotOn Become A Partner" />
      <img
        src={bg}
        alt="background"
        className="hidden md:block absolute top-20 right-0 "
      />
      <BecomeAPartnerThankYou />
    </Layout>
  );
};

export default AffiliatePage;
